export default [
  'responsive-web-design',
  'javascript-algorithms-and-data-structures',
  'front-end-libraries',
  'data-visualization',
  'apis-and-microservices',
  'information-security-and-quality-assurance',
  'full-stack',
  'legacy-front-end',
  'legacy-back-end',
  'legacy-data-visualization'
];
